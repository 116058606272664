import React from "react"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/noone/Layout"
import SEO from "../../components/Seo"
import Faq from "../../components/shared/faq/Faq"
import { PAGE } from "../../constants"

const faq = () => {
  return (
    <Layout>
      <SEO title={PAGE.title.faq} siteTitle="noone" />
      <HeadingL title={PAGE.title.faq} target="noone" />
      <Faq target="noone" />
    </Layout>
  )
}

export default faq
